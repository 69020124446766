:root {
    --ck-z-default: 2000;
	--ck-z-modal: 2000 !important;
 }
.custom-rte {
    width: 100%;
}
.custom-rte .ck-toolbar,
.custom-rte .ck-content {
    border-color: #10626c !important;
    background-color: transparent !important;
}
.custom-rte .ck-content {
    height: 150px;
    white-space: normal;
}
.custom-rte-edit .ck-toolbar,
.custom-rte-edit .ck-content {
    border-color: #c6c6c6 !important;
}
.custom-rte-disable {
    color: #9e9e9e;
}