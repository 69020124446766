
.form-add-ann{
    background-color: #E2E2E2;
    margin: auto;
    width: 50%;
    border-radius: 15px;
    padding: 35px;
}

.box-form-add-ann{
    background-color: #212428;
    margin: auto;
    width: 100%;
    border-radius: 15px;
    padding: 30px;
    color: white;
    box-shadow: 2px 2px 5px rgb(126, 126, 126);
}


.img-box{
    background: white;
}

img{
    height: 200px;
    padding: 5px;
}

.btn{
    border-radius: 50px;
}

textarea{
    max-height: 105px;
}

.row{
    margin-bottom: 15px;
}


.test{
    margin-left: 30%;
    margin-right: 30%;
    background-color: brown;
}


