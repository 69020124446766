.announcement{
    background-color: #F6F6F4;
}

.tilte-ann{
    margin-left: 7%;
    margin-top: 2%;
    margin-bottom: 2%;
}

.card-img-top{
    height: 15vw;
    object-fit: contain;
    /* background-color: #f5f5f5; */
}

#card-ann{
    border-radius: 1rem;
    box-shadow: 1px 1px 5px rgb(231, 231, 231);
    text-align: center;
}

#btn{
    border-radius: 50px;
    /* box-shadow: 1px 1px 5px rgb(216, 216, 216); */
    margin: 5px;
}


#row-custom{
    margin-left: 7%;
    margin-right: 7%;
}

#col-custom{
    margin-bottom: 1%;
}


