.custom-rte .ck-content {
    height: 300px;
}

.custom-rte .ck-content.ck-read-only {
    color: gray;
}

.custom-rte.work-history .ck-content {
    max-height: 300px;
}